document.addEventListener('turbolinks:load', function (event) {
    if (typeof (gtag) == 'function') {
        var code_tag = document.getElementById('google-analytics-id');
        if(code_tag) {
            gtag('config', code_tag.content, {
                'page_title': event.target.title,
                'page_path': event.data.url.replace(window.location.protocol + "//" + window.location.hostname, "")
            });
        }
    }
});