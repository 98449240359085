import { Controller } from "stimulus"

export default class extends Controller {
  static targets  = ["input"]
  select(event) {
    this.inputTarget.setSelectionRange(0,9999)
  }

  copy(event) {
    this.inputTarget.select()
    document.execCommand("copy")
    event.target.innerHTML = "Copied"
    setTimeout(() => {
      event.target.innerHTML = "Copy"
    }, 2000)
  }
}